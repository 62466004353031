



































































































































































































































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import {
  AddNewPaymentMethod,
  AddStripeCustomerBillingDetails, AddStripeCustomerTaxDetails, MeQuery,
  UpdateStripeCustomerBillingDetails,
  UpdateStripeCustomerTaxDetails
} from "@/graphql/auth";
import * as ct from "countries-and-timezones";
import CountryFlag from 'vue-country-flag'
import PaymentMethods from "@/components/subscription/PaymentMethods.vue";
import AddNewCard from "@/components/subscription/AddNewCard.vue";
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import Template from "@/components/templates/Template.vue";
import {CreateSetupIntent} from "@/graphql/WorkspaceSubscription";
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";
import VerifyUser from '../auth/VerifyUser.vue';

@Component({
  components: {Template, AddNewCard, PaymentMethods, CountryFlag, StripeElementCard},
})

export default class EditBillingDetails extends Mixins(ValidationErrorParserMixin) {
  @Prop() public haveBillingDetails!: boolean;
  @Prop() public userEmail!: string;
  @Prop() public userName!: string;
  isCompany: string = 'individual';
  name: string | null = this.userName;
  email: string | null = this.userEmail;
  firstName: string | null = null;
  lastName: string | null = null;
  country: string | null = null;
  companyName: string | null = null;
  address: string | null = null;
  city: string | null = null;
  zipCode: string | null = null;
  taxId: string | null = null;
  addAddressActive: boolean = false;
  taxList: Array<Object> = [
    {
      'country_code': 'AD', 'tax_type': 'ad_nrt',
    },
    {
      'country_code': 'AR', 'tax_type': 'ar_cuit'
    },
    {
      'country_code': 'AU', 'tax_type': 'au_abn'
    },
    {
      'country_code': 'AU', 'tax_type': 'au_arn'
    },
    {
      'country_code': 'AT', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'BE', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'BO', 'tax_type': 'bo_tin'
    },
    {
      'country_code': 'BR', 'tax_type': 'br_cnpj'
    },
    {
      'country_code': 'BR', 'tax_type': 'br_cpf'
    },
    {
      'country_code': 'BG', 'tax_type': 'bg_uic'
    },
    {
      'country_code': 'BG', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'CA', 'tax_type': 'ca_bn'
    },
    {
      'country_code': 'CA', 'tax_type': 'ca_gst_hst'
    },
    {
      'country_code': 'CA', 'tax_type': 'ca_pst_bc'
    },
    {
      'country_code': 'CA', 'tax_type': 'ca_pst_mb'
    },
    {
      'country_code': 'CA', 'tax_type': 'ca_pst_sk'
    },
    {
      'country_code': 'CA', 'tax_type': 'ca_qst'
    },
    {
      'country_code': 'CL', 'tax_type': 'cl_tin'
    },
    {
      'country_code': 'CN', 'tax_type': 'cn_tin'
    },
    {
      'country_code': 'CO', 'tax_type': 'co_nit'
    },
    {
      'country_code': 'CR', 'tax_type': 'cr_tin'
    },
    {
      'country_code': 'HR', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'CY', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'CZ', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'DK', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'DO', 'tax_type': 'do_rcn'
    },
    {
      'country_code': 'EC', 'tax_type': 'ec_ruc'
    },
    {
      'country_code': 'EG', 'tax_type': 'eg_tin'
    },
    {
      'country_code': 'SV', 'tax_type': 'sv_nit'
    },
    {
      'country_code': 'EE', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'EU', 'tax_type': 'eu_oss_vat'
    },
    {
      'country_code': 'FI', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'FR', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'FR', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'GE', 'tax_type': 'ge_vat'
    },
    {
      'country_code': 'DE', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'GR', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'HK', 'tax_type': 'hk_br'
    },
    {
      'country_code': 'HU', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'HU', 'tax_type': 'hu_tin'
    },
    {
      'country_code': 'IS', 'tax_type': 'is_vat'
    },
    {
      'country_code': 'IN', 'tax_type': 'in_gst'
    },
    {
      'country_code': 'ID', 'tax_type': 'id_npwp'
    },
    {
      'country_code': 'IE', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'IL', 'tax_type': 'il_vat'
    },
    {
      'country_code': 'IL', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'IT', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'JP', 'tax_type': 'jp_cn'
    },
    {
      'country_code': 'JP', 'tax_type': 'jp_rn'
    },
    {
      'country_code': 'JP', 'tax_type': 'jp_trn'
    },
    {
      'country_code': 'KE', 'tax_type': 'ke_pin'
    },
    {
      'country_code': 'LV', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'LI', 'tax_type': 'li_uid'
    },
    {
      'country_code': 'LT', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'LU', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'MY', 'tax_type': 'my_frp'
    },
    {
      'country_code': 'MY', 'tax_type': 'my_itn'
    },
    {
      'country_code': 'MY', 'tax_type': 'my_sst'
    },
    {
      'country_code': 'MT', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'MX', 'tax_type': 'mx_rfc'
    },
    {
      'country_code': 'NL', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'NZ', 'tax_type': 'nz_gst'
    },
    {
      'country_code': 'NO', 'tax_type': 'no_vat'
    },
    {
      'country_code': 'PE', 'tax_type': 'pe_ruc'
    },
    {
      'country_code': 'PH', 'tax_type': 'ph_tin'
    },
    {
      'country_code': 'PO', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'PL', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'PT', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'RO', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'RO', 'tax_type': 'ro_tin'
    },
    {
      'country_code': 'RU', 'tax_type': 'ru_inn'
    },
    {
      'country_code': 'RU', 'tax_type': 'ru_kpp'
    },
    {
      'country_code': 'SA', 'tax_type': 'sa_vat'
    },
    {
      'country_code': 'RS', 'tax_type': 'rs_pib'
    },
    {
      'country_code': 'SG', 'tax_type': 'sg_gst'
    },
    {
      'country_code': 'SG', 'tax_type': 'sg_uen'
    },
    {
      'country_code': 'SK', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'SL', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'SI', 'tax_type': 'si_tin'
    },
    {
      'country_code': 'ZA', 'tax_type': 'za_vat'
    },
    {
      'country_code': 'KR', 'tax_type': 'kr_brn'
    },
    {
      'country_code': 'ES', 'tax_type': 'es_cif'
    },
    {
      'country_code': 'ES', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'SE', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'CH', 'tax_type': 'ch_vat'
    },
    {
      'country_code': 'TW', 'tax_type': 'tw_vat'
    },
    {
      'country_code': 'TH', 'tax_type': 'th_vat'
    },
    {
      'country_code': 'TR', 'tax_type': 'tr_tin'
    },
    {
      'country_code': 'UA', 'tax_type': 'ua_vat'
    },
    {
      'country_code': 'AE', 'tax_type': 'ae_trn'
    },
    {
      'country_code': 'GB', 'tax_type': 'eu_vat'
    },
    {
      'country_code': 'GB', 'tax_type': 'gb_vat'
    },
    {
      'country_code': 'US', 'tax_type': 'us_ein'
    },
    {
      'country_code': 'UY', 'tax_type': 'uy_ruc'
    },
    {
      'country_code': 'UE', 'tax_type': 've_rif'
    },
    {
      'country_code': 'VN', 'tax_type': 'vn_tin'
    },
  ]

  selectedTaxType: string | null = null;
  countryList: Object = {}
  isLoading: boolean = false
  showCard: boolean = true

  cardNumber: any | null = null
  ccBrand: string | null = null
  cardExpiry: any | null = null
  cardCvc: any | null = null
  cardNumberError: string | null = null;
  cardExpiryError: string | null = null;
  cardCvcError: string | null = null;
  cardNumberErrorFlag: boolean = true;
  cardExpiryErrorFlag: boolean = true;
  cardCvcErrorFlag: boolean = true;
  cardAdded: boolean = false;
  cardPresent: boolean = false;


  get me() {
    return this.$store.state.me;
  }

  get countrySortedList() {
    let country = Object.values(this.countryList);

    return country.sort(function(a,b){
      return a.name.localeCompare(b.name);
    })
  }

  get isDisabled() {
    if(this.name && this.email && this.country != null ) {
      if(this.isCompany === 'individual') {
        if(this.cardPresent) {
          return false;
        }else {
          if(this.showCard && (!this.cardNumberErrorFlag && !this.cardExpiryErrorFlag && !this.cardCvcErrorFlag)) {
            return false
          }else{
            if(!this.showCard) {
              return false;
            }
            return true;
          }
        }
      }else{
        if(this.companyName != null && this.selectedTaxType != null && this.taxId != null) {
          if(this.cardPresent) {
            return false;
          }else {
            if(this.showCard && (!this.cardNumberErrorFlag && !this.cardExpiryErrorFlag && !this.cardCvcErrorFlag)) {
              return false
            }else{
              if(!this.showCard) {
                return false;
              }
              return true;
            }
          }
        }
        return true;

      }
    }
    return true;
    // if(this.cardPresent) {
    //   return false;
    // }else{
    //   if(this.isCompany === 'individual') {
    //     if(this.name && this.email && this.country != null ) {
    //       if(this.showCard && (!this.cardNumberErrorFlag && !this.cardExpiryErrorFlag && !this.cardCvcErrorFlag)){
    //
    //         return false;
    //       }else{
    //         if(!this.showCard) {
    //           return false;
    //         }
    //         return true;
    //       }
    //
    //     }else{
    //       return true
    //     }
    //   }else{
    //     if(this.name && this.email && this.country != null && this.companyName != null && this.selectedTaxType != null && this.taxId != null ) {
    //       if(this.showCard && (!this.cardNumberErrorFlag && !this.cardExpiryErrorFlag && !this.cardCvcErrorFlag)){
    //         return false;
    //       }else{
    //         if(!this.showCard) {
    //           return false;
    //         }
    //         return true;
    //       }
    //
    //     }else{
    //       return true
    //     }
    //   }
    // }

  }

  get stripeElements () {
    //@ts-ignore
    return this.$stripe.elements();
  }

  get couponCode() {
    return this.$store.state.coupon_code;
  }

  get isOnGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get currentPlan() {
    if(this.workspace.currentSubscription !== null) {
      return this.workspace.currentSubscription.plan.title
    }
    else { return null }
  }
 
  created() {
    this.countryList = ct.getAllCountries();

    if(this.me.billingDetails.length > 0) {
      this.isCompany = this.me.billingDetails[0].account_type;
      this.address = this.me.billingDetails[0].line1;
      this.country = this.me.billingDetails[0].country;
      this.city = this.me.billingDetails[0].city;
      this.zipCode = this.me.billingDetails[0].postal_code;
      this.email = this.me.billingDetails[0].email;
      if(this.me.billingDetails[0].account_type == 'individual') {
        let name = this.me.billingDetails[0].name.split(' ');
        this.firstName = name[0];
        this.lastName = name[1];
        this.name = this.firstName+ ' ' + this.lastName;
      }else if(this.me.billingDetails[0].account_type == 'company'){
        this.companyName = this.name = this.me.billingDetails[0].name;
      }
    }

    if(this.me.taxDetails.length > 0) {
      this.taxId = this.me.taxDetails[0].tax_id;
      this.selectedTaxType = this.me.taxDetails[0].tax_type
  
    }


  }



  mounted() {
   
    if(this.isOnGuestAccount) {
      this.name = null;
      this.email = null;
    } else {
      this.name = this.userName;
      this.email = this.userEmail;
    }

    this.$events.listen('defaultPaymentMethod', (eventData: any) => {

      if(eventData) {
        this.showCard = false;
        this.cardPresent = true;
      }else{
        this.showCard = true;
        this.cardPresent = false;
      }
      if(this.haveBillingDetails) {
        this.$store.commit('set_checkout_step', 3);
      }
    })



    const style = {
      style: {
        base: {
          iconColor: "#000",
          color: "#000",
          fontWeight: "800",
          fontFamily: "Press Start 2P",
          fontSize: "22px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#fce883"
          },
          "::placeholder": {
            color: "green"
          }
        },
        invalid: {
          iconColor: "#FFC7EE",
          color: "red"
        }
      }
    };
    this.cardNumber = this.stripeElements.create('cardNumber', {style});
    this.cardNumber?.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', {style});
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', {style});
    this.cardCvc?.mount('#card-cvc');



    this.cardNumber.on('change', (event: any) => {
     
      if(event.complete) {
        this.cardNumberErrorFlag = false;
        this.ccBrand = event.brand;
      }else{
        this.ccBrand = null;
        this.cardNumberErrorFlag = true;
        if(event.error) {
          this.cardNumberError = event.error.message
        }else{
          this.cardNumberError = null;
        }
      }

    })

    this.cardExpiry.on('change', (event: any) => {
      if(event.complete) {
        this.cardExpiryErrorFlag = false;
      }else{
        this.cardExpiryErrorFlag = true;
        if(event.error) {
          this.cardExpiryError = event.error.message
        }else{
          this.cardExpiryError = null;
        }
      }

    })

    this.cardCvc.on('change', (event: any) => {
      if(event.complete) {
        this.cardCvcErrorFlag = false;
      }else{
        this.cardCvcErrorFlag = true;
        if(event.error) {
          this.cardCvcError = event.error.message
        }else{
          this.cardCvcError = null;
        }
      }

    })



    this.stripeElements.on('change', (_event: any) => {
     
    })
  }

  initiateLoading() {
    this.$emit('loading-start', true);
  }
  stopInitiatedLoading() {
    this.$emit('loading-stop', true);
  }

  triggerNewSubscription() {
    this.isLoading = true;
    this.$emit('start-new-subscription', true);
  }

  updateBillingAndTax() {
    let splitName = this.name?.split(' ');
    this.firstName = splitName![0];
    this.lastName = splitName![1]
    this.isLoading = true;
    this.$gtag.event('pmb_app_final_checkout_save_billing_details_attempt');
    if(this.me.billingDetails.length > 0 && this.me.billingDetails[0].id) {
      this.$apollo.mutate({
        mutation: UpdateStripeCustomerBillingDetails,
        variables: {
          id: this.me.billingDetails[0].id,
          name: (this.isCompany == 'individual') ? this.firstName?.trim() + ' ' + this.lastName?.trim() : this.companyName,
          email: this.email,
          accountType: this.isCompany,
          line1: this.address,
          country: this.country,
          city: this.city,
          postal_code: this.zipCode,
          isDefault: true

        },
        refetchQueries: [{query: MeQuery}]
      }).then((_res:any) => {
        
        this.$emit('details-confirmed', true);
        this.$emit('next-step', true);
        
        this.$gtag.event('pmb_app_final_checkout_save_billing_details_success');
        if(this.taxId && this.selectedTaxType) {
          this.saveTaxDetails();
        }else{
          if(this.showCard) {
            this.createSetupIntent();
          }
          this.isLoading = false;
          this.$buefy.toast.open({
            message: 'Billing details updated',
            position: 'is-bottom-right',
            type: 'is-black',
          });
          // if(!this.showCard) {
          //   //@ts-ignore
          //   this.$parent.close();
          // }
        }
      }).catch((error: any) => {
        this.isLoading = false;
        this.$gtag.event('pmb_app_final_checkout_save_billing_details_error', {error: error.message})
        this.parseValidationError(error)
        this.$buefy.toast.open({
          message: this.fieldValidationMessage('billingDetails'),
          position: "is-bottom-right",
          type: "is-warning",
        });
      })
    }else{
      this.initiateLoading();
      this.$apollo.mutate({
        mutation: AddStripeCustomerBillingDetails,
        variables: {
          name: (this.isCompany == 'individual') ? this.firstName?.trim() + ' ' + this.lastName?.trim() : this.companyName,
          email: this.email,
          accountType: this.isCompany,
          line1: this.address,
          country: this.country,
          city: this.city,
          postal_code: this.zipCode,
          isDefault: true
        },
        refetchQueries: [{query: MeQuery}]
      }).then((_res:any) => {
        
        if(this.taxId && this.selectedTaxType) {
          this.saveTaxDetails();
        }else{
          if(this.showCard) {
            this.createSetupIntent();
          }
          this.isLoading = false;
          this.$buefy.toast.open({
            message: 'Billing details added',
            position: 'is-bottom-right',
            type: 'is-black',
          });
          // if(!this.showCard) {
          //   //@ts-ignore
          //   this.$parent.close();
          // }
        }

      }).catch((error: any) => {
        this.isLoading = false;
        this.parseValidationError(error)
        this.$buefy.toast.open({
          message: this.fieldValidationMessage('billingDetails'),
          position: "is-bottom-right",
          type: "is-warning",
        });

      })
      
    }
  }

  saveTaxDetails() {
    if(this.me.taxDetails.length > 0 && this.me.taxDetails[0].id) {
      this.$apollo.mutate({
        mutation: UpdateStripeCustomerTaxDetails,
        variables: {
          id: this.me.taxDetails[0].id,
          tax_type: this.selectedTaxType,
          tax_id: this.taxId
        }
      }).then((_res: any) => {
        if(this.showCard) {
          this.createSetupIntent();
        }

        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Billing and tax details updated',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        // if(!this.showCard) {
        //   //@ts-ignore
        //   this.$parent.close();
        // }

      }).catch((error: any) => {
        this.isLoading = false;
        this.parseValidationError(error)
        this.$buefy.toast.open({
          message: this.fieldValidationMessage('taxDetails'),
          position: "is-bottom-right",
          type: "is-warning",
        });
      })
    }else{
      this.$apollo.mutate({
        mutation: AddStripeCustomerTaxDetails,
        variables: {
          tax_type: this.selectedTaxType,
          tax_id: this.taxId
        },
        refetchQueries: [{query: MeQuery}]
      }).then((_res: any) => {
        if(this.showCard) {
          this.createSetupIntent();
        }
        this.isLoading = false;
        this.$buefy.toast.open({
          message: 'Billing and tax details updated',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        //@ts-ignore
        //this.$parent.close();
        
      }).catch((error: any) => {
        this.isLoading = false;
        this.parseValidationError(error)
        this.$buefy.toast.open({
          message: this.fieldValidationMessage('taxDetails'),
          position: "is-bottom-right",
          type: "is-warning",
        });
      })
    }

  }

  getTaxName(tax_id: string) {
    const taxname = tax_id.replace(/_/g, " ");
    return taxname.toUpperCase();
  }

  getCountry(countryCode: string) {
    const country = ct.getCountry(countryCode);
    if(country) {
      return country.name;
    }

  }

  createSetupIntent() {
    this.isLoading = true;
    this.$apollo.mutate({
      mutation: CreateSetupIntent,
      refetchQueries: [{query: MeQuery}]
    }).then((res: any) => {

      if(res.data.createSetupIntent && res.data.createSetupIntent.client_secret) {
        this.confirmCardSetup(res.data.createSetupIntent.client_secret);
      }

    }).catch((error: any) => {
      this.isLoading = false;
      //this.parseValidationError(error)
      Vue.prototype.$consoleLog(error);
    })
  }

  confirmCardSetup(secret: string) {
    //@ts-ignore
    this.$stripe.confirmCardSetup(secret, {
      payment_method: {
        card: this.cardNumber,
        billing_details: {
          name: this.me.name,
          email: this.me.email,
        }
      }
    }).then((res: any) => {
      if(res.error) {
        this.$buefy.toast.open({
          message: res.error.message,
          position: 'is-bottom-right',
          type: 'is-danger',
          duration: 3000
        });
        this.isLoading = false;
      }else{
        this.cardAdded = true;
        this.addNewPaymentMethod(res.setupIntent.payment_method);
      }

    }).catch((error: any) => {
      this.isLoading = false;
      this.parseValidationError(error)
    });
  }

  addNewPaymentMethod(payment_method: string) {
    this.$apollo.mutate({
      mutation: AddNewPaymentMethod,
      variables: {
        stripe_customer_id: this.me.stripe_customer_id,
        paymentMethod: payment_method
      },
    }).then((res: any) => {
      this.$emit('next-step', true);
     
      this.isLoading = false;
     
      this.$events.fire('allcards', res.data.addNewCard);
      this.$buefy.toast.open({
        message: 'New card added',
        position: 'is-bottom-right',
        type: 'is-black',
      });
      //@ts-ignore
      this.$parent.close();

 

    }).catch((error: any) => {
      this.isLoading = false;
      this.parseValidationError(error)
    })
  }

  openVerifyUser() {
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        clickedClaim: true,
        claimWs: true,
        fromCheckout: true
      },
      width: '500px',
      parent: this,
      canCancel: false,
    });
  }
  


}
